import { yupResolver } from '@hookform/resolvers/yup';
import type {
  DialogContentProps,
  DialogProps,
  DialogTriggerProps,
} from '@radix-ui/react-dialog';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type FC, type PropsWithChildren, useState } from 'react';
import type { InferType } from 'yup';
import { object, string } from 'yup';

import useForm from '@/hooks/useForm';
import { queries } from '@/queries';
import { postExternalCandidatesCollection } from '@/services/externalCandidates';

import Button from '../Button';
import MultiLineInput from '../Form/Fields/MultiLineInput';
import TextInputField from '../Form/Fields/TextInputField';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
} from '../ui/dialog';

type Props = {
  dialogProps?: DialogProps;
  dialogTriggerProps?: DialogTriggerProps;
  dialogContentProps?: DialogContentProps;
  candidates?: number[];
  onSuccess?: (
    value: Awaited<ReturnType<typeof postExternalCandidatesCollection>>
  ) => void;
};

const AddCollectionDialog: FC<PropsWithChildren<Props>> = ({
  children,
  dialogContentProps,
  dialogProps,
  dialogTriggerProps,
  candidates,
  onSuccess,
}) => {
  const queryClient = useQueryClient();
  const [dialogOpen, setDialogOpen] = useState(false);
  const { register, handleSubmit, reset } = useForm<InferType<typeof schema>>({
    resolver: yupResolver(schema),
  });
  const { mutate, isLoading } = useMutation({
    mutationFn: postExternalCandidatesCollection,
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries(
        queries.externalCandidates.collections.queryKey
      );
      if (variables.candidates && variables.candidates.length > 0)
        Promise.all(
          variables.candidates.map((id) =>
            queryClient.invalidateQueries(
              queries.externalCandidates.detail(id).queryKey
            )
          )
        );
      onSuccess?.(response);
      setDialogOpen(false);
      dialogProps?.onOpenChange?.(false);
    },
  });
  return (
    <Dialog
      open={dialogOpen}
      {...dialogProps}
      onOpenChange={(open) => {
        setDialogOpen(open);
        dialogProps?.onOpenChange?.(open);
        reset();
      }}
    >
      <DialogTrigger {...dialogTriggerProps}>{children}</DialogTrigger>
      <DialogContent {...dialogContentProps}>
        <DialogHeader>Create collection</DialogHeader>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit((data) =>
              mutate({
                description: data.description,
                title: data.name,
                candidates,
              })
            )(e);
            e.stopPropagation();
          }}
          className="flex flex-col gap-3"
        >
          <TextInputField
            variant="transparent"
            labelClassName="text-neutral-1000"
            label="Name"
            {...register('name')}
          />
          <MultiLineInput
            variant="transparent"
            labelClassName="text-neutral-1000"
            label="Description"
            {...register('description')}
          />
          <Button isLoading={isLoading} type="submit">
            Submit
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddCollectionDialog;

const schema = object({
  name: string().defined(),
  description: string().defined(),
});
