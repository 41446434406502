import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react';
import {
  Tooltip,
  TooltipProvider,
  TooltipTrigger,
} from '@mindpal-co/mindpal-ui';
import {
  type DialogContentProps,
  type DialogProps,
  type DialogTriggerProps,
} from '@radix-ui/react-dialog';
import { useRouter } from 'next/router';
import type { FC, PropsWithChildren } from 'react';
import { Controller } from 'react-hook-form';
import type { InferType } from 'yup';
import { mixed, number, object, string } from 'yup';

import { useExternalCandidatesCollections } from '@/hooks/useExternalCandidatesCollections';
import useForm from '@/hooks/useForm';
import { useCSVImportStore } from '@/stores/useCSVImportStore';

import Button from '../Button';
import AddCollectionDialog from '../Dashboard/AddCollectionDialog';
import FileInput from '../Form/Fields/FileInput';
import Label from '../Form/Fields/Label';
import StepNumberAndName from '../RecruiterSettings/TemplateRequest/StepNumberAndName';
import { Select } from '../Select';
import Text from '../Text';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
} from '../ui/dialog';
import { TooltipContent } from '../ui/tooltip';

type Props = {
  dialogTriggerProps?: DialogTriggerProps;
  dialogContentProps?: DialogContentProps;
  dialogProps?: DialogProps;
};

const UploadDialog: FC<PropsWithChildren<Props>> = ({
  dialogContentProps,
  dialogProps,
  dialogTriggerProps,
  children,
}) => {
  const router = useRouter();
  const {
    setFile,
    setCollection,
    collection: chosenCollection,
    file,
  } = useCSVImportStore();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<InferType<typeof schema>>({
    resolver: yupResolver(schema),
    defaultValues: {
      file,
      collection: chosenCollection,
    },
  });

  const { data: collectionsData } = useExternalCandidatesCollections({
    staleTime: 1000 * 60 * 5,
  });

  const collectionsOptions = collectionsData?.results.map((collection) => ({
    value: collection.id,
    label: collection.title,
  }));

  return (
    <Dialog {...dialogProps}>
      <DialogTrigger {...dialogTriggerProps}>{children}</DialogTrigger>
      <DialogContent {...dialogContentProps}>
        <StepNumberAndName
          disableStepButtons
          currentIndex={1}
          variant="csvLight"
          setStep={() => {}}
          steps={['Upload', 'Mapping', 'Summary']}
        />
        <DialogHeader>Candidates import</DialogHeader>
        <Text variant="body-small" className="text-neutral-1000">
          Import Your database to MindPal - it should contain a full name and
          resume columns.
        </Text>
        <form
          onSubmit={handleSubmit((data) => {
            setFile(data.file);
            setCollection(data.collection);
            router.push('/csv');
          })}
          className="flex flex-col gap-5"
        >
          <Controller
            control={control}
            name="file"
            render={({ field: { onChange, ...field } }) => (
              <FileInput
                label="Database file"
                labelClassName="text-neutral-1000"
                {...field}
                onChange={(newValue) => onChange(newValue ?? null)}
                variant="transparent"
                accept={{ 'text/csv': ['.csv'] }}
                error={errors.file?.message}
              />
            )}
          />
          <div>
            <div className="flex items-center gap-1">
              <Icon
                className="text-2xl text-neutral-1000"
                icon="material-symbols-light:folder-open-outline"
              />
              <Label className="text-neutral-1000">Collection</Label>
              <TooltipProvider>
                <Tooltip delayDuration={0}>
                  <TooltipContent className="max-w-[13rem] rounded-lg bg-neutral-200">
                    <Text className="text-neutral-1000">
                      Create a group from the candidates from the file You just
                      uploaded.
                    </Text>
                  </TooltipContent>
                  <TooltipTrigger>
                    <Icon
                      icon="ri:question-line"
                      className="text-xl text-neutral-300"
                    />
                  </TooltipTrigger>
                </Tooltip>
              </TooltipProvider>
            </div>
            <div className="flex flex-col gap-4">
              <Controller
                control={control}
                name="collection"
                render={({ field: { ref: _ref, ...field } }) => (
                  <Select
                    isClearable
                    autoFocus
                    variant="transparent"
                    options={collectionsOptions}
                    className="flex-1"
                    {...field}
                  />
                )}
              />
              <AddCollectionDialog
                onSuccess={(val) =>
                  setValue('collection', { label: val.title, value: val.id })
                }
              >
                <div className="flex items-center gap-1">
                  <Icon
                    className="text-2xl text-neutral-1000"
                    icon="material-symbols-light:add-circle-outline"
                  />
                  <Text className="text-neutral-1000 underline">
                    Create new collection
                  </Text>
                </div>
              </AddCollectionDialog>
            </div>
          </div>
          <Button type="submit">Submit</Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default UploadDialog;

const schema = object({
  file: mixed<File>().required(),
  collection: object({
    value: number().defined(),
    label: string().defined(),
  }).nullable(),
});
